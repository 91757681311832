// body {
//     font-family: "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
//     font-size  : 14px;
// }

// .form-input-label {
//   width: 100px;
// }

// .form-input-field {
//   width: 4000px;
// }

.right {
  display: flexbox;
  text-align: right;
}

.abs-right {
  position: absolute;
  right: 2em;
}

.left {
  display: flexbox;
  text-align: left;
}

.grouped-buttons > .ui-button:not(:first-child) {
  margin-left: 16px;
}

.ellipsis {
  background-color: pink;
  // white-space  : nowrap;
  // overflow     : hidden;
  // text-overflow: ellipsis;
}

.logo {
  display: flex;
  text-align: left;
  // margin-left : auto;
  // margin-right: auto;
  // width       : 15%;
  height: 56px;
}

// .header {
//   margin: auto;
//   width: 100%;
//   font-size: x-large;
//   font-weight: bold;
//   padding: 0.5em;
//   text-align: center;
//   background-color: #f5f5f5; // #999999; // #007D40;
//   color: black;
// }

.p-breadcrumb {
  border: 0px;
}

.dekra-header {
  display: block;
  padding: 0.5em;
  width: 100%;
  font-size: x-large;
  font-weight: bold;
  // padding         : 1em;
  text-align: center;
  background-color: #015b27;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 60px;
}


.layout-wrapper {
  .layout-topbar {
    .layout-menu-button {
      z-index: 5000;
      opacity: 1;
    }
  }
}

.signin-card {
  // background-color    : gray;
  text-align: center;
  // // position  : relative;
  // // top       : 10%;
  // height           : 100%;
  // vertical-align: middle;
}

.signin-header {
  font-size: 50px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.signing-data-warning {
  background-color: #007d40;
  color: white;
  padding: 10px;
  text-align: center;
}

.green-label {
  color: #007d40;
  font-weight: bold;
}

.green-link {
  color: #007d40;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .signin-header {
    font-size: 30px;
    padding-top: 1em;
    margin-bottom: 1em;
  }
}

.signin-topbar {
  background-color: #007d40;
  height: 70px;
  text-align: center;

  .signin-topbar-logo {
    width: 100%;
    height: 70px;

    img {
      vertical-align: text-bottom;
      // margin  : 10px 5em 0 0;
      margin-top: 23px;
      padding-left: 10px;
      padding-right: 10px;
      max-width: 100%;
      max-height: 24px;
    }
  }
}


.media-preview {
  img {
    max-width: 400px;
    height: auto;
  }

  video {
    max-width: 400px;
    height: auto;
  }
}

.top-menu {
  position: fixed;
  top: 26px;
  left: 360px;
  color: white;
  //background-color: red;
  //text-overflow: ellipsis;
  /* padding: 5px; */
  max-height: 20px;
//  min-width: 100px;
  /* display: block; */
  overflow: hidden;
  direction: ltr;
  margin-right: 60px;
  text-align: left;
}

.top-menu-items span {
  margin-right: 1em;
  white-space: nowrap;
  //text-overflow: ellipsis;
  display: inline-block;
  color: white;
  //padding: 8px;
  overflow: hidden;
  padding: 0px;

  a {
    color: white;
  }

  a:hover {
    font-weight: bold;
  }
}



@media screen and (max-width: 550px) {
  .top-menu {
    display: none;
  }
}

@media screen and (max-width: 490px) {
  .layout-profile-name {
    display: none;
  }
}

.main-title {
  font-size: 56px;
  font-weight: bold;
  color: #015b27;
}

.main-subtitle {
  font-size: 53px;
}


.main-ingress {
  font-size: 18px;
  line-height: 1.6em;

  color: #292929;
}

.main-question {
  font-size: 20px;
}

.quiz-no {
  font-size: 40px;
  font-weight: 500;
  color: #015b27;
}

.quiz-wrong {
  font-size: 40px;
  font-weight: 500;
  color: #e60000; // red; // #015b27;
  text-align: left;
  border-bottom: 1px solid black;
  overflow: auto;
  display: flex; /* defines flexbox */
  align-items: flex-end;
}

.quiz-correct {
  font-size: 40px;
  font-weight: 500;
  color: #015b27;
  text-align: left;
  border-bottom: 1px solid black;
  overflow: auto;
  display: flex; /* defines flexbox */
  align-items: flex-end;
}

.quiz-hivo-img {
  max-width: 120px;
  float: right;
  margin-left: auto;
}

.quiz-header {
  color: #015b27;
  font-weight: bold;
}

.quiz-new {
  background-color: #ee7f00; // orange;
  color: white;
  float: right;
  font-weight: bold;
  padding: 4px 12px 4px 12px;
}

.quiz-check {
  color: #015b27;
  float: right;
}

.correct-icon {
  //  float: right;
  //   font-size: x-large;
  color: #57ab27;
  text-align: right;
  padding-top: 10px;
  vertical-align: bottom;
}

.wrong-icon {
  // font-size: x-large;
  color: #e60000;
  text-align: right;
  padding-top: 10px;
  vertical-align: bottom;
}

// body .ui-dataview .ui-dataview-content {
//   padding: 0px;
//   border: 0px;
// }

.card-header {
  // background-color: gray;
  // color: white;
  font-weight: bold;
  // padding-t      : .5em;
  margin-bottom: 0em;
  width: 100%;
  display: block;
}

.big-card-header {
  // background-color: gray;
  // color: white;
  font-weight: bold;
  padding-bottom: 0.5em;
  margin-bottom: 0em;
  width: 90%;
  display: block;
  font-weight: bold;
}


.fa-lock:before {
  content: "\f023";
  color: white;
}

.page-grid-top {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {
  .quiz-no {
    font-size: 32px;
  }

  .main-question {
    font-size: 26px;
  }

  .main-title {
    font-size: 46px;
  }

  .main-subtitle {
    font-size: 43px;
  }
}

@media screen and (max-width: 490px) {
  .quiz-no {
    font-size: 24px;
  }

  .main-question {
    font-size: 16px;
  }

  .main-title {
    font-size: 36px;
  }

  .main-subtitle {
    font-size: 33px;
  }

  .data-card {
    margin-right: 0px;
    max-width: 100%;
  }
}

.bullet-3d-g {
  color: #007d40;
}

.bullet-3d-o {
  color: #ee7f00;
}

.bullet-3d-b {
  color: #a0a0a0;
}

.bullet-3d-null {
  color: #0000f0;
}

.bullet-3d-undefined {
  color: #ff0000;
}

$primaryDarkestColor: #004516;
$primaryDarkerColor: #006636;
$primaryDarkColor: #006636;
$primaryColor: #007d40;
$primaryLightColor: #56aa26;
$primaryLighterColor: #57ab27;
$primaryLightestColor: #a1d48d;
$primaryColorText: #ffffff;
$primaryTextColor: #ffffff;
$warnMessageBgColor: teal;

$highlightBg: lightgray;
$highlightTextColor: black;
$primaryLightestColor: #ffffff;

// @import "../../sass/theme/_theme_variables";
// @import "../../sass/theme_core/_core";
@import "../../sass/theme/_variables.scss";
@import "../../sass/theme/_theme.scss";

